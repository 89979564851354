<template>
    <data-loading-component v-if="dataLoading"/>
    <div v-else class="bg-4">
        <contact-banner :details="banner"></contact-banner>
        <div class="container pt-5 pb-4">
            <div class="row" v-if="details.length!==0">

                <div class="col-12">
                    <heading-with-info class="" :bottom-tag="false" title="Latest News"/>
                </div>

                <div class="col-12">
                    <tabs color="primary" ref="cTabOne" align="center" class="c-tabs header-semi-bold m-0">
                        <tab title="All">
                            <div class="row">
                                <div v-for="(item,i) in details" :key="i" class="col-12 col-lg-4 col-md-6">
                                    <news-card
                                        :id="item.id.toString()"
                                        :hour="item.created_at"
                                        :heading="item.title"
                                        :content="item.full_description.substring(0, 140)+' ...'"
                                        :img="item.image"
                                        :categ="item.category"
                                    ></news-card>
                                </div>
                            </div>
                        </tab>
                        <tab v-for="(categ,index) in category" :key="index" :title="categ">
                            <div class="row">
                                <template v-for="(item, i) in details">
                                    <div :key="i" class="col-12 col-lg-4 col-md-6" v-if="item.category===categ">
                                        <news-card @details="loadDetails"
                                                   :id="item.id.toString()"
                                                   :hour="item.created_at"
                                                   :heading="item.title"
                                                   :content="item.full_description.substring(0, 140)+' ...'"
                                                   :img="item.image"
                                                   :categ="item.category"
                                        ></news-card>
                                    </div>
                                </template>
                            </div>
                        </tab>
                    </tabs>
                </div>
            </div>

            <div v-else class="row">
                <div class="col-12 text-center pb-3 text-primary">
                    <a :href="'/course-offered/'"><h4>Explore our wide variety of courses offered.</h4></a>
                </div>
            </div>

        </div>
    </div>
</template>

<script>
import HeadingWithInfo from '../components/HeadingWithInfo';
import NewsCard from '../components/home/news/NewsCard';
import axios from 'secure-axios';
import urls from '../data/urls';
import DataLoadingComponent from '../components/ui/DataLoadingComponent';
import ContactBanner from '../components/contact-us/Contact-Banner';

export default {
    name: 'AllNewsPage',
    components: { ContactBanner, DataLoadingComponent, NewsCard, HeadingWithInfo },
    data () {
        return {
            category: [],
            details: '',
            banner: '',
            dataLoading: false
        };
    },
    beforeMount () {
        this.loadDetails();
    },
    methods: {
        async loadDetails () {
            this.dataLoading = true;
            const response = await axios.get(urls.website.news);
            const json = response.data;
            if (json.error === false) {
                this.details = json.data;
                this.banner = json.banner;
                this.setCategory();
            } else {
                this.$notify('Could not load data, Please try again later', 'Error', {
                    type: 'danger'
                });
            }
            this.dataLoading = false;
        },
        setCategory () {
            for (let i = 0; i < this.details.length; i++) {
                if (this.category.indexOf(this.details[i].category) === -1) {
                    this.category.push(this.details[i].category);
                }
            }
        }
    }
};
</script>

<style scoped>

</style>
