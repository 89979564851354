<template>
    <div class="mb-4" :class="{'bottom-tag':bottomTag, 'text-center':align==='center','text-left':align==='left'}">
        <h3 class="mb-3 font-jost-semi">{{ title }}</h3>
        <p class="font-inter-medium text-3 text-center px-lg-9 lh-2">{{ info }}</p>
    </div>
</template>
<script> export default {
    name: 'HeadingWithInfo',
    props: {
        title: {
            type: String
        },
        info: {
            type: String
        },
        align: {
            type: String,
            default: 'center'
        },
        bottomTag:
            {
                type: Boolean,
                default: false
            }
    }
};
</script>
<style scoped lang="scss">
.bottom-tag {
    &::after {
        content: '';
        position: absolute;
        //width: 5%;
        width: 3rem;
        transform: translateX(-50%);
        height: 2px;
        background-color: rgba(0,0,0,0.65);
        margin-top: 1rem;
    }
}
</style>
