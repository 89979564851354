<template>
    <div v-if="details.length!==0" class="contact-banner" :style="banner(details[0].banner_image)">
        <div class="row">
            <div class="col-12 col-lg-12 ">

            </div>
        </div>
    </div>
    <div v-else class="contact-banner">
        <div class="row">
            <div class="col-12 col-lg-12 ">

            </div>
        </div>
    </div>
</template>
<script>
export default {
    name: 'ContactBanner',
    props: {
        details: { type: Array, default: null }
    },
    components: {},
    methods: {
        banner (image) {
            return { backgroundImage: 'url(' + image + ')' };
        }
    }
};
</script>
<style scoped>
.contact-banner {
    background-image: url('../../assets/img/web/contact us/bgi.jpg');
    min-height: 45vh;
    background-size: cover;
    background-position: 0% 50%;
}

@media (max-width: 750px) {
    .contact-banner {
        padding: 0;
        background-position: center 100%;
        background-size: cover;
        min-height: 35vh;
        width: 100%;
    }
}
</style>
