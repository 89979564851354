<template>
    <div class="b-1 pb-4 bg-2 mb-4" @click="viewNews">
        <div class="card-min-h"
             :style="{'background-image':'url('+ img +')', 'background-size':'cover', 'background-repeat':'no-repeat', 'background-position':'0% 0%'}">

        </div>
        <div class="ml-3 mt-3">
            <h6 class="font-weight-bold font-jost-semi fs-2">{{ heading }}</h6>
            <p class="fs--1 mt-3 font-lato-medium text-3 align-justify pr-2"> {{ content }} </p>
            <span class="text-secondary font-inter-bold text-uppercase fs--1 mr-2">{{ categ }}</span>
            <span class="font-inter-regular text-3 fs--1 border-left pl-2">{{ hour }}</span>
        </div>
    </div>
</template>
<script>

export default {
    name: 'NewsCard',
    components: {},
    props: {
        id: {
            type: String,
            default: ''
        },
        heading: {
            type: String,
            default: ''
        },
        route: {
            type: String,
            default: ''
        },
        hour: {
            type: String,
            default: ''
        },
        categ: {
            type: String,
            default: ''
        },
        source: {
            type: String,
            default: ''
        },
        size: {
            type: String,
            default: 'sm'
        },
        author: {
            type: String,
            default: ''
        },
        img: {
            type: String
        },
        content: {
            type: String,
            default: ''
        },
        link: {
            type: String,
            default: '/'
        },
        nextLink: {
            type: String,
            default: '/'
        },
        prevLink: {
            type: String,
            default: '/'
        }
    },
    methods: {
        viewNews () {
            this.$emit('details', this.id);
            this.$router.push('/news/' + this.id);
        }
    }
};
</script>
<style lang="scss" scoped>
.card-min-h {
    position: relative;
    height: 11rem;

    img {
        width: 100% !important;
        height: 100% !important;
    }
}

.border-left {
    border-left: 1px solid var(--text-2);
}
</style>
