<template>
    <div class="full-page" title="">
        <div class="w-30r"><div class="container py-5 text-primary">
            <div class="fl-y fl-a-c fl-j-c">
                <loading-animation class="fa-3x"></loading-animation>
                <h6 class="mt-4">Please wait while data is being loaded.</h6>
            </div>
        </div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'DataLoadingComponent'
};
</script>

<style scoped>
.full-page {
    height: 100vh;
    display: flex;
    align-content: center;
    justify-content: center;
    align-items: center;
}
</style>
