import { render, staticRenderFns } from "./AllNewsPage.vue?vue&type=template&id=02266e98&scoped=true&"
import script from "./AllNewsPage.vue?vue&type=script&lang=js&"
export * from "./AllNewsPage.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "02266e98",
  null
  
)

export default component.exports